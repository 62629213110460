import React from "react";
import { BaseModal } from "../BaseModal";
import { CloseButton } from "../../styles";
import { BaseModalProps } from "../../types";
import { color } from "@styles";
import { Cross } from "components/atoms/svgs/Cross";
import { P1 } from "components/atoms/Paragraph";
import { buttonVariant } from "components/atoms/Button";
import {
  ModalHeader,
  ModalImage,
  ModalHeaderContent,
  StyledHeading,
  ModalContent,
  ModalButtonContainer,
  ButtonContainerLeft,
  ModalButton,
  ButtonContainerRight,
} from "./styles";

type ModalProps = BaseModalProps & {
  header: string;
  description?: string;
  image?: React.ReactNode;
  tertiaryButtonLeft?: React.ReactNode;
  tertiaryButtonRight?: React.ReactNode;
  onTertiaryButtonLeftClick?: () => void;
  onTertiaryButtonRightClick?: () => void;
};

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  closeOnClickOutside,
  showModal,
  closeModal,
  background,
  header,
  description,
  image,
  primaryButton,
  secondaryButton,
  tertiaryButtonLeft,
  tertiaryButtonRight,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onTertiaryButtonLeftClick,
  onTertiaryButtonRightClick,
  children,
}) => {
  return (
    <BaseModal
      background={background}
      closeModal={closeModal}
      closeOnClickOutside={closeOnClickOutside}
      showModal={showModal}
    >
      <>
        <ModalHeader>
          <ModalImage>{image}</ModalImage>

          <ModalHeaderContent spacing="1rem">
            <StyledHeading tag="h1">{header}</StyledHeading>
            {description && <P1 pt="0">{description}</P1>}
          </ModalHeaderContent>
        </ModalHeader>

        <ModalContent>{children}</ModalContent>

        <ModalButtonContainer>
          <ButtonContainerLeft>
            {primaryButton && (
              <ModalButton onClick={onPrimaryButtonClick} minwidth="0">
                {primaryButton}
              </ModalButton>
            )}
            {tertiaryButtonLeft && (
              <ModalButton
                onClick={onTertiaryButtonLeftClick}
                minwidth="0"
                variant={buttonVariant.text}
              >
                {tertiaryButtonLeft}
              </ModalButton>
            )}
          </ButtonContainerLeft>
          <ButtonContainerRight>
            {tertiaryButtonRight && (
              <ModalButton
                onClick={onTertiaryButtonRightClick}
                minwidth="0"
                variant={buttonVariant.text}
              >
                {tertiaryButtonRight}
              </ModalButton>
            )}
            {secondaryButton && (
              <ModalButton
                onClick={onSecondaryButtonClick}
                minwidth="0"
                variant={buttonVariant.secondary}
              >
                {secondaryButton}
              </ModalButton>
            )}
          </ButtonContainerRight>
        </ModalButtonContainer>
        <CloseButton onClick={closeModal} aria-label="close">
          <Cross color={color.B1100} />
        </CloseButton>
      </>
    </BaseModal>
  );
};
