import { YuLifeLocale } from "hooks";
import Image from "next/image";
import { ReactElement } from "react";
import { CURRENT_YEAR } from "utils/_constants";
import { FlexContainer, StyledLink, StyledP } from "./styles";
import Link from "next/link";
import { P1 } from "components/atoms/Paragraph";

type Data = Record<
  YuLifeLocale,
  {
    legalDisclaimer: ReactElement;
    bupaDisclaimer?: ReactElement;
    privateHealthDisclaimer?: ReactElement;
  }
>;

const data: Data = {
  uk: {
    legalDisclaimer: (
      <P1>
        {CURRENT_YEAR} © Yu Life Ltd. Yu Life Ltd is authorised and regulated by the Financial
        Conduct Authority. Firm reference No. 783352. Yu Life Ltd is a company registered in
        England, company no. 10308260, registered office: 12 Mallow Street, London, EC1Y 8RQ.
      </P1>
    ),
    bupaDisclaimer: (
      <>
        <P1 pt="0">
          1{" "}
          <Link href="https://www.cdc.gov/chronicdisease/about/prevent/">
            https://www.cdc.gov/chronicdisease/about/prevent/
          </Link>
        </P1>
        <P1>
          2{" "}
          <Link href="https://www.bupa.co.uk/~/media/Files/MMS/bins-01113">
            https://www.bupa.co.uk/~/media/Files/MMS/bins-01113
          </Link>
        </P1>
        <P1>
          3 As of March 2024, this comparison to other products in the market is based on Bupa’s and
          Defaqto’s interpretation of the differences between the Bupa’s SME Select health insurance
          and other health insurance products offering mental health cover. The comparison excludes
          any special offers or promotions which may temporarily alter the cover offered. Cover
          comparison information is for personal use and guidance only and does not constitute any
          contractual representation, warranty or obligation by either Bupa or Defaqto about the
          completeness, accuracy, reliability, suitability or availability of the comparison.
          Neither party accepts any liability for errors, omissions, direct or consequential loss in
          relation to this comparison. See bupa.co.uk/compare
        </P1>
        <P1>
          4 With Bupa cancer cover there are no time limits. All eligible treatment costs and
          evidence-based breakthrough cancer drugs and treatments that your scheme covers are paid
          in full for as long as your employee has Bupa health cover. Employees will need to check
          their certificate or guide to see which specific list of advanced therapies has been
          selected as it may not cover all advanced therapies. Your employees will need to use a
          hospital or clinic from the Bupa network that applies to their cover and a Bupa recognised
          consultant who will charge within our rates (a fee-assured consultant).
        </P1>
        <P1>5 YuLife data 2023</P1>
        <P1>
          6 A commissioned Forrester Consulting TEI Study. Results are for a composite organisation
          and represent the incremental value of YuLife's health and wellbeing engagement.
        </P1>
        <P1>
          7 Available on Bupa Select Health Insurance through YuLife only. Not every product or
          service will be available to claim each policy year. Employees unlock additional products
          and services through engagement with YuLife over time. This is in addition to normal
          YuCoin rewards for activities including mindfulness, walking, and cycling. The specific
          products and services may be subject to change, and are provided by YuLife partners
          outside of the scope of Bupa Select Health Insurance.
        </P1>
        <P1>
          Bupa health insurance is provided by Bupa Insurance Limited. Registered in England and
          Wales with registration number 3956433. Bupa Insurance Limited is authorised by the
          Prudential Regulation Authority and regulated by the Financial Conduct Authority and the
          Prudential Regulation Authority. Arranged and administered by Bupa Insurance Services
          Limited, which is authorised and regulated by the Financial Conduct Authority. Registered
          in England and Wales with registration number 3829851. Registered office: 1 Angel Court,
          London EC2R 7HJ
        </P1>
      </>
    ),
    privateHealthDisclaimer: (
      <>
        <P1>
          ¹
          <Link href="https://www.cdc.gov/chronicdisease/about/prevent/">
            {" "}
            https://www.cdc.gov/chronicdisease/about/prevent/
          </Link>
        </P1>
        <P1 pt="0">
          ²
          <Link href="https://www.bupa.co.uk/~/media/Files/MMS/bins-01113">
            {" "}
            https://www.bupa.co.uk/~/media/Files/MMS/bins-01113
          </Link>
        </P1>
        <P1>
          Bupa health insurance is provided by Bupa Insurance Limited. Registered in England and
          Wales No. 3956433. Bupa Insurance Limited is authorised by the Prudential Regulation
          Authority and regulated by the Financial Conduct Authority and the Prudential Regulation
          Authority. Arranged and administered by Bupa Insurance Services Limited, which is
          authorised and regulated by the Financial Conduct Authority. Registered in England and
          Wales No. 3829851. Registered office: 1 Angel Court, London EC2R 7HJ
        </P1>
      </>
    ),
  },
  us: {
    legalDisclaimer: (
      <>
        <P1 pt="0">
          {CURRENT_YEAR} © YuLife Insurance Agency, Inc. YuLife Insurance Agency, Inc. is a
          corporation registered in the State of Delaware and located at 3379 Peachtree Road NE
          (Buckhead), Suite 555, Atlanta, GA 30326.
        </P1>
      </>
    ),
  },
  za: {
    legalDisclaimer: (
      <FlexContainer>
        <StyledP>
          YuLife SA Pty Ltd is an authorised financial services provider (FSP No 52478). Products
          are underwritten by Guardrisk Life Limited, an authorised financial services provider (FSP
          No 76) and a licenced life insurer.
        </StyledP>
        <Image
          src="/static/logos/guardrisk-logo.png"
          width={213}
          height={31}
          alt="Guard risk logo"
        />
      </FlexContainer>
    ),
  },
  jp: {
    legalDisclaimer: <p>{CURRENT_YEAR} © Yu Life Ltd.</p>,
  },
};

export default data;
