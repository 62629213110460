import { FC, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { NavbarType } from "../organisms/Navigation/components/Navbar/Navbar.types";
import { Footer } from "../organisms/Footer";
import { useClientOnly, useLocale } from "hooks";
import dynamic from "next/dynamic";
import { Vimeo } from "@services";
import { GTMPreferences, useCookiePreferences } from "hooks/useCookiePreferences";
import { ClientOnly } from "components/templates/ClientOnly";
import { AsciiYugi } from "components/templates/AsciiYugi";
import { UserLocaleModal } from "components/organisms/Modal";
import { NavBar } from "components/molecules/NavBar";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getCookie, getNavigatorLocale } from "@utils";
import { LatestCaseStudy } from "utils/server";

const CookiesBar = dynamic(() => import("../organisms/CookiesBar"), { loading: () => null });

export const Page: FC<
  React.PropsWithChildren<{
    hero?: JSX.Element;
    isInverted?: boolean;
    background?: string;
    navColor?: string;
    fullScreenHero?: boolean;
    hidePressBar?: boolean;
    hideFooter?: boolean;
    hideLinks?: boolean;
    hideRegionSelector?: boolean;
    latestCaseStudy?: LatestCaseStudy;
    topPageToolbar?: JSX.Element;
  }>
> = ({
  hero,
  isInverted,
  background,
  fullScreenHero,
  hidePressBar = false,
  hideFooter = false,
  hideLinks = false,
  hideRegionSelector = false,
  children,
  latestCaseStudy,
  topPageToolbar,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const query = useSearchParams();

  const shouldOpenLocaleModal = query.get("openLocaleModal") === "true";

  const { locale } = useLocale();

  useEffect(() => {
    const utmString = window.location.href.match(/(\&|\?)utm_[A-Za-z]+=[A-Za-z0-9]+/gi);
    if (utmString) {
      try {
        sessionStorage.setItem("utm", utmString.join(""));
      } catch (e) {
        // do nothing
      }
    }

    if (process.env.ENV_NAME === "production") {
      console.info(AsciiYugi);
    }
  }, []);

  const { hasMounted } = useClientOnly();

  const [videoId, setVideoId] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const preloadVideo = (vimeoId: string) => {
    setVideoId(vimeoId);
  };

  const triggerVideo = () => {
    setShowVideo(true);
  };

  const [showUserLocaleModal, setShowUserLocaleModal] = useState(false);

  useEffect(() => {
    setShowUserLocaleModal(shouldOpenLocaleModal);
  }, [shouldOpenLocaleModal]);

  const closeLocaleModal = () => {
    setShowUserLocaleModal(false);
  };

  const [showCookiesBar, setShowCookiesBar] = useState(false);
  const [openOnCookiesModal, setOpenOnCookiesModal] = useState(false);

  const { updateCookiePreferences } = useCookiePreferences();

  useEffect(() => {
    if (!hasMounted) {
      return;
    }

    const nextLocaleCookie = getCookie("NEXT_LOCALE");

    if (!nextLocaleCookie) {
      const navigatorLocale = getNavigatorLocale();

      if (navigatorLocale && locale !== "jp") {
        if (navigatorLocale !== locale) {
          setShowUserLocaleModal(true);
        }
      }
    }

    try {
      if (window.localStorage.getItem("gdpr") === null && locale !== "jp") {
        setShowCookiesBar(window.localStorage.getItem("gdpr") === null);
      } else {
        updateCookiePreferences(JSON.parse(window.localStorage.getItem("gdpr")) as GTMPreferences);
      }

      // We want to remove the old cookies check. One day we can remove this code.
      if (window.localStorage.getItem("accept_cookies")) {
        window.localStorage.removeItem("accept_cookies");
      }
    } catch (e) {
      // do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted, pathname, router, updateCookiePreferences]);

  return (
    <ClientOnly>
      <div style={{ background }}>
        <ThemeContext.Provider
          value={{
            preloadVideo,
            triggerVideo,
            openCookieModal: () => {
              setShowCookiesBar(true);
              setOpenOnCookiesModal(true);
            },
          }}
        >
          <NavContainer>
            {topPageToolbar ? <>{topPageToolbar}</> : null}
            <NavBar
              navbarType={isInverted ? NavbarType.primaryInverted : NavbarType.primary}
              hideLinks={hideLinks}
              hideRegionSelector={hideRegionSelector}
              latestCaseStudy={latestCaseStudy}
              topToolbar={!!topPageToolbar}
            />
          </NavContainer>
          <ClientOnly>
            <Vimeo
              isOpen={showVideo}
              vimeoId={videoId}
              onClose={() => {
                setShowVideo(false);
                setVideoId("");
              }}
            />
            <UserLocaleModal openModal={showUserLocaleModal} closeModal={closeLocaleModal} />
          </ClientOnly>
          {hero ? (
            <Header role="banner" fullScreen={fullScreenHero}>
              {hero}
            </Header>
          ) : null}
          {showCookiesBar ? (
            <CookiesBar
              onSave={() => setShowCookiesBar(!showCookiesBar)}
              openOnModal={openOnCookiesModal}
            />
          ) : null}
          <Main id="main" role="main">
            {children}
          </Main>
          {hideFooter ? null : <Footer hidePressBar={hidePressBar} />}
        </ThemeContext.Provider>
      </div>
    </ClientOnly>
  );
};

const NavContainer = styled.div`
  position: sticky;
  top: -0.1rem;
  z-index: 10;
  height: 8rem;

  @media only screen and (max-width: 45rem) {
    height: 6.4375rem;
  }

  @media only screen and (max-width: 30rem) {
    height: 5.5rem;
  }
`;

const Header = styled.header<{ fullScreen?: boolean }>`
  position: relative;
  max-width: 100vw;
  ${(p) =>
    p.fullScreen
      ? `
      margin-top: -8.75rem;

      @media only screen and (max-width: 45rem) {
        margin-top: -6.4rem;
      }

      @media only screen and (max-width: 30rem) {
        margin-top: -4.75rem;
      }
      `
      : null}
`;

const Main = styled.main`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
`;
