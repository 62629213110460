import { FC, useState } from "react";
import { MobileFriendlyP1, EarthContainer, Padding, StyledLegend, StyledFieldset } from "./styles";
import { CloseButton } from "../../styles";
import { color, color as themeColor } from "@styles";
import { useLocale, YuLifeLocale } from "hooks";
import { BaseModal } from "../../components/BaseModal";
import { LanguageSwitcher } from "components/organisms/LanguageSwitcher";
import { getLocaleName, languageOptions } from "@utils";
import { Cross } from "components/atoms/svgs/Cross";
import { usePathname } from "next/navigation";
import { useRouter as usePagesRouter } from "next/router";

const CLOSE_TIMING = 1200; // ms

interface Props {
  openModal: boolean;
  closeModal: () => void;
}

export const UserLocaleModal: FC<Props> = ({ openModal, closeModal }) => {
  const pagesRouter = usePagesRouter();
  const pathname = usePathname();
  const { locale, path, setLocale } = useLocale();
  const [isClosing, setIsClosing] = useState(false);

  return (
    <BaseModal
      background={themeColor.B220}
      closeModal={closeModal}
      closeOnClickOutside={false}
      showModal={openModal}
      maxWidth="50rem"
    >
      <>
        <Padding spacing="1rem">
          <StyledFieldset>
            <StyledLegend>Select your region.</StyledLegend>
            <MobileFriendlyP1 pt="0.25rem" pb="1rem" lineHeight="1.75rem">
              You’re on the <strong>{getLocaleName(locale)}</strong> version of the site. Did you
              want to change your region?
            </MobileFriendlyP1>
            <LanguageSwitcher
              altLangs={languageOptions}
              path={path}
              onClick={(e, country: YuLifeLocale) => {
                e.preventDefault();

                setIsClosing(true);

                setTimeout(() => {
                  closeModal();
                  setIsClosing(false);
                  setLocale(country);

                  const newPath = pathname.startsWith("/404") ? "/" : pathname;

                  pagesRouter.push(newPath, undefined, { locale: country || "uk" });
                }, CLOSE_TIMING);
              }}
            />
          </StyledFieldset>
        </Padding>
        <EarthContainer isClosing={isClosing} closeTiming={CLOSE_TIMING}>
          <img src="/static/svg/earth.svg" alt="Earth" height={312} width={312} />
        </EarthContainer>
        <CloseButton onClick={closeModal} aria-label="close">
          <Cross color={color.B1100} />
        </CloseButton>
      </>
    </BaseModal>
  );
};
