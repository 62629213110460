import { Link } from "@components/atoms";
import { usePathname } from "next/navigation";
import { FC, Dispatch, SetStateAction, useState, useMemo } from "react";
import { ExitIcon } from "../../../../../../atoms/ExitIcon";
import { Logo } from "../../../../../../atoms/Logo";
import { StandardPadding } from "../../../../../../templates/StandardPadding";
import { MenuName, NavbarType, NavLinks as NavLinksType } from "../../Navbar.types";
import MobileNavArrow from "./components/MobileNavArrow/MobileNavArrow";
import PushLeft from "./components/PushLeft/PushLeft";
import { FullScreen, LI1, LI3, NavButtonMobile, StandaloneLI } from "./MobileNav.styles";
import { Anchor, Padding, LogoContainer, MenuWrapper, UL, NarrowLI } from "../../Navbar.styles";
import { useHandleLocaleSwitch } from "@hooks";
import { MobileNavLink } from "./MobileNavLink";

const MobileNav: FC<
  React.PropsWithChildren<{
    setOpenMobileNav: Dispatch<SetStateAction<boolean>>;
    links: NavLinksType;
    navButtonText?: string;
    hideLinks?: boolean;
    hideRegionSelector?: boolean;
  }>
> = ({ setOpenMobileNav, links, navButtonText, hideLinks = false, hideRegionSelector = false }) => {
  const [openMenu, setOpenMenu] = useState(MenuName.blank);
  const pathname = usePathname();

  const handleLocaleSwitch = useHandleLocaleSwitch();

  const handleClick = (slug) => {
    if (slug.replace(/\//g, "") === pathname.replace(/\//g, "")) {
      setOpenMobileNav(false);
    }
  };

  const filteredLinks = useMemo(
    () =>
      links.filter((link) => {
        if (!hideLinks) {
          return true;
        }

        return !hideRegionSelector && link.title === "Region";
      }),
    [hideLinks, hideRegionSelector, links],
  );

  return (
    <FullScreen>
      <Padding>
        <Link passHref href="/" legacyBehavior>
          <LogoContainer onClick={() => handleClick("/")}>
            <Logo id="yulife-logo-fullscreen" color="#ffffff" />
          </LogoContainer>
        </Link>
        <MenuWrapper>
          <UL>
            <NarrowLI onClick={() => setOpenMobileNav(false)}>
              <ExitIcon />
            </NarrowLI>
          </UL>
        </MenuWrapper>
      </Padding>
      <StandardPadding>
        <ul>
          {filteredLinks?.map((linkGroup, index) => (
            <div key={index}>
              {linkGroup.primaryLinks.length > 0 && (
                <>
                  <LI1
                    onClick={() =>
                      setOpenMenu(openMenu === linkGroup.title ? MenuName.blank : linkGroup.title)
                    }
                  >
                    {linkGroup.titleTranslation || linkGroup.title}
                    <MobileNavArrow isOpen={openMenu === linkGroup.title} />
                  </LI1>
                  <ul>
                    {linkGroup.primaryLinks
                      .filter((item) => item.isLive === undefined || item.isLive === true)
                      .map((linkProps) => (
                        <PushLeft
                          key={linkProps.link}
                          hasEntered={openMenu === linkGroup.title}
                          transitionDelay={50}
                        >
                          <MobileNavLink
                            handleLocaleSwitch={handleLocaleSwitch}
                            handleClick={handleClick}
                            {...linkProps}
                          />
                        </PushLeft>
                      ))}
                  </ul>
                </>
              )}
              {linkGroup.secondaryLinks && (
                <>
                  <LI1
                    onClick={() =>
                      setOpenMenu(
                        openMenu === linkGroup.secondaryTitle
                          ? MenuName.blank
                          : linkGroup.secondaryTitle,
                      )
                    }
                  >
                    {linkGroup.secondaryTitle}
                    <MobileNavArrow isOpen={openMenu === linkGroup.secondaryTitle} />
                  </LI1>
                  <ul>
                    {linkGroup.secondaryLinks
                      .filter((item) => item.isLive === undefined || item.isLive === true)
                      .map((linkProps) => (
                        <PushLeft
                          key={linkProps.link}
                          hasEntered={openMenu === linkGroup.secondaryTitle}
                          transitionDelay={50}
                        >
                          <MobileNavLink handleClick={handleClick} {...linkProps} />
                        </PushLeft>
                      ))}
                  </ul>
                </>
              )}
            </div>
          ))}
          {hideLinks ? null : (
            <>
              <StandaloneLI>
                <Link passHref href="/careers/" legacyBehavior>
                  <Anchor>We’re hiring!</Anchor>
                </Link>
              </StandaloneLI>
              <LI3 onClick={() => handleClick("/get-a-quote/")}>
                <Link passHref href="/get-a-quote/" legacyBehavior>
                  <NavButtonMobile buttonType={NavbarType.primaryInverted}>
                    {navButtonText || "Get a quote"}
                  </NavButtonMobile>
                </Link>
              </LI3>
            </>
          )}
        </ul>
      </StandardPadding>
    </FullScreen>
  );
};

export default MobileNav;
