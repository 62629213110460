import React, { FC } from "react";
import { useLocale } from "@hooks";
import { FooterLinks } from "./links.types";
import ukFooterLinks from "./ukFooterLinks";
import usFooterLinks from "./usFooterLinks";
import { NavTitle, NavListItem, NavLinksGrid } from "./links.styles";
import zaFooterLinks from "./zaFooterLinks";

const Links: FC<{}> = () => {
  const { locale } = useLocale();
  let links: FooterLinks;

  switch (locale) {
    case "us":
      links = usFooterLinks;
      break;
    case "za":
      links = zaFooterLinks;
      break;
    default:
      links = ukFooterLinks;
  }

  return (
    <NavLinksGrid numberOfSections={links.length}>
      {links.map((linkGroup) => (
        <div key={linkGroup.id} aria-labelledby={linkGroup.id}>
          <NavTitle id={linkGroup.id}>{linkGroup.title}</NavTitle>
          <ul>
            {linkGroup.links
              .filter((item) => item.isLive === undefined || item.isLive === true)
              .map((link, index) => {
                const linkLocale = link.locale || locale;
                const linkHref = linkLocale === "uk" ? link.link : `/${linkLocale}${link.link}`;

                return (
                  <NavListItem key={`${link.link}-${index}`}>
                    {link.external ? (
                      <a href={link.link} target="_blank" rel="noopener noreferrer">
                        {link.text}
                      </a>
                    ) : (
                      <a href={linkHref}>{link.text}</a>
                    )}
                  </NavListItem>
                );
              })}
          </ul>
        </div>
      ))}
    </NavLinksGrid>
  );
};

export default Links;
