import { PressBar } from "./PressBar";
import { useState, useCallback, ChangeEvent, FC, FormEvent } from "react";
import styled from "styled-components";
import { Link } from "@components/atoms";
import translationData from "./translations";
import { Logo } from "../../atoms/Logo";
import { P1, P2 } from "../../atoms/Paragraph";
import { HubspotForms, isEmail, languageOptions } from "@utils";
import { color, fonts } from "@styles";
import { FacebookIcon, XIcon, LinkedInIcon, InstagramIcon } from "./socialIcons";
import { Spacer } from "../../templates/Spacer";
import { StandardSection } from "../../templates/StandardSection";
import { Image } from "../../atoms/Image";
import Links from "./components/Links/Links";
import {
  NEWSLETTER_SIGN_UP_BUTTON_ID,
  NEWSLETTER_SIGN_UP_BUTTON_MOBILE_ID,
  NEWSLETTER_SIGN_UP_FORM_ID,
  TEST_DOMAIN_EMAILS,
} from "utils/_constants";
import { mobile } from "components/templates/Breakpoints";
import { useLocale } from "hooks";
import { UserLocaleModal } from "../Modal";
import { CountryLink } from "../LanguageSwitcher";
import { useLocaleData } from "hooks/useLocaleData";
import { useHubspotForm } from "hooks/useHubspotForm";
import { usePathname } from "next/navigation";
import { StaticRoutes } from "@routes";
import { AlternateFormField } from "components/atoms/AlternateFormField";

export const Footer: FC<{ hidePressBar?: boolean }> = ({ hidePressBar = false }) => {
  const [email, setEmail] = useState("");
  const [fakeEmail, setFakeEmail] = useState("");

  const [isShaking, setIsShaking] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const [showUserLocaleModal, setShowUserLocaleModal] = useState(false);
  const { locale } = useLocale();
  const { legalDisclaimer, bupaDisclaimer, privateHealthDisclaimer } =
    useLocaleData(translationData);
  const pathname = usePathname();

  const { handleFormSubmit } = useHubspotForm();

  const closeLocaleModal = () => {
    setShowUserLocaleModal(false);
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setEmail(e.currentTarget.value);
      setIsSubmitSuccessful(false);
    },
    [setEmail],
  );

  const handleSubmit = useCallback(
    (_e: FormEvent<HTMLFormElement>) => {
      if (fakeEmail) {
        return;
      }

      if (!isEmail(email)) {
        setIsShaking(true);
        setIsError(true);
        setIsSubmitSuccessful(false);
      } else {
        setIsError(false);
        setIsSubmitSuccessful(true);

        const formData = {
          fields: [{ name: "email", value: email }],
          context: {
            pageUri: window ? window.location.href : "https://yulife.com/",
            pageName: window ? window.location.href + " (Footer)" : "Footer",
          },
        };

        const name = HubspotForms.blogSignUp;

        handleFormSubmit(name, formData);
      }

      setTimeout(() => {
        setIsShaking(false);
      }, 820);
    },
    [email, fakeEmail, handleFormSubmit],
  );

  const IS_UK_HEALTH_INSURANCE_PAGE =
    pathname === StaticRoutes.insuranceGroupHealthInsurance && locale === "uk";

  const IS_UK_PRIVATE_INSURANCE_PAGE =
    pathname === StaticRoutes.privateHealthInsurance && locale === "uk";

  return (
    <footer id="footer" role="contentinfo" style={{ overflow: "hidden" }}>
      {hidePressBar ? null : <PressBar />}
      <FooterBackground>
        <StandardSection pt="5rem" pb="5rem">
          <div>
            <FlexRow>
              <LogoRow>
                <Pointer>
                  <Link href="/">
                    <Logo id="yulife-logo-footer-flex" />
                  </Link>
                </Pointer>
              </LogoRow>
              <InputRow>
                {isSubmitSuccessful ? (
                  <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <Image
                      width="5rem"
                      path="/img/rebrand/svgs/YugiAvatar.svg"
                      alt="Happy giraffe"
                    />
                    <P1 pl="1.15rem">Thanks for signing up!</P1>
                  </div>
                ) : (
                  <NewsletterSignUpForm
                    isInputValid={!isShaking}
                    id={NEWSLETTER_SIGN_UP_FORM_ID}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    <Label>Sign up here for news and updates:</Label>
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                      <Input
                        title="email"
                        type="text"
                        value={email}
                        placeholder={
                          locale === "za"
                            ? "Sign up here for email updates"
                            : "Sign up here for news and updates"
                        }
                        onChange={handleChange}
                        isError={isError}
                        isSubmitSuccessful={isSubmitSuccessful}
                      />
                      <InputMobile
                        title="email"
                        type="text"
                        value={email}
                        placeholder=""
                        onChange={handleChange}
                        isError={isError}
                        isSubmitSuccessful={isSubmitSuccessful}
                      />
                      <AlternateFormField onChange={(e) => setFakeEmail(e.target.value)} />
                    </div>
                    <ButtonContainer>
                      <SignUpButton
                        type="submit"
                        isDisabled={!isEmail(email)}
                        id={NEWSLETTER_SIGN_UP_BUTTON_ID}
                        value="Sign up"
                      />
                    </ButtonContainer>
                    <SignUpButtonMobile
                      type="submit"
                      isDisabled={!isEmail(email)}
                      id={NEWSLETTER_SIGN_UP_BUTTON_MOBILE_ID}
                      value="Sign up"
                    />
                  </NewsletterSignUpForm>
                )}
                <ErrorText pt="0.5rem">
                  {isError ? "Error: please enter a valid email address" : ""}
                </ErrorText>
              </InputRow>
              <BCorpLogo src="/static/logos/BCorpLogo.svg" alt="B-Corp Logo" />
            </FlexRow>
            <Links />
            <Spacer height={["4.25rem", "3.25rem"]} />
            <CountrySelector>
              <strong>Select Region</strong>
              <CountryLink
                country={languageOptions[locale]?.country}
                flag={languageOptions[locale]?.flag}
                onClick={() => setShowUserLocaleModal(true)}
              />
            </CountrySelector>
            <UserLocaleModal openModal={showUserLocaleModal} closeModal={closeLocaleModal} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <hr style={{ width: "100%", border: "none", borderTop: "1px solid #D9D9D7" }} />
              <SocialIcons style={{ marginLeft: "1rem" }}>
                <a href="https://www.facebook.com/yulifestories/">
                  <FacebookIcon />
                </a>
                <a href="https://www.linkedin.com/company/yulife/">
                  <LinkedInIcon />
                </a>
                <a href="https://www.instagram.com/yulifestories/">
                  <InstagramIcon />
                </a>
                <a href="https://twitter.com/yulife/">
                  <XIcon />
                </a>
              </SocialIcons>
            </div>
            <Disclaimer>
              {IS_UK_HEALTH_INSURANCE_PAGE ? bupaDisclaimer : null}
              {IS_UK_PRIVATE_INSURANCE_PAGE ? privateHealthDisclaimer : null}
              {legalDisclaimer}
            </Disclaimer>
          </div>
        </StandardSection>
      </FooterBackground>
    </footer>
  );
};

const Pointer = styled.div`
  cursor: pointer;
`;

const CountrySelector = styled.div`
  position: absolute;
  display: block;
  background-color: ${color.B210};
  font-family: ${fonts.body};
  padding-right: 1rem;
  margin-top: -0.7rem;

  ${mobile} {
    position: static;
    padding-right: 0.25rem;
  }
`;

const FooterBackground = styled.div`
  background: ${color.B210};
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 55rem) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LogoRow = styled.div`
  margin-right: 2rem;

  @media only screen and (max-width: 55rem) {
    display: none;
  }
`;

const Label = styled(P2)`
  text-align: center;

  @media only screen and (min-width: 20rem) {
    display: none;
  }
`;

const InputRow = styled.div`
  width: 100%;
  max-width: 65em;

  @media only screen and (max-width: 55rem) {
    max-width: 100%;
  }
`;

const NewsletterSignUpForm = styled.form<{ isInputValid?: boolean }>`
  position: relative;
  ${(p) =>
    p.isInputValid ? null : `animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both`};
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const Input = styled.input<{ isError: boolean; isSubmitSuccessful: boolean }>`
  display: inline-block;
  width: 100%;
  max-width: 46rem;
  border: 0.125rem solid
    ${(p) => (p.isError ? "#FF5F5F" : p.isSubmitSuccessful ? "${color.P100}" : "#e3e3e1")};
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  color: ${color.B1100};
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  ::placeholder {
    font-weight: 400;
    color: ${color.B2100};
  }

  :focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
    ${(p) => (p.isError || p.isSubmitSuccessful ? "outline: none" : "")}
  }

  @media only screen and (max-width: 40rem) {
    padding-bottom: 5.5rem;
  }

  @media only screen and (max-width: 20rem) {
    display: none;
  }

  ${mobile} {
    font-size: 0.95rem;
  }
`;

const InputMobile = styled(Input)`
  display: none;

  @media only screen and (max-width: 20rem) {
    display: inline-block;
  }
`;

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 0.8rem;

  @media only screen and (max-width: 40rem) {
    display: none;
  }
`;

const SignUpButtonHover = `
  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0 rgba(0, 237, 157, 0.2);
    color: ${color.white};
    background: ${color.P100};
  }
`;

const SignUpButtonDisabled = `
  color: ${color.white};
  background: ${color.P100};
  border: 2px solid ${color.P100};
  cursor: not-allowed;
`;

const SignUpButton = styled.input<{ isDisabled: boolean }>`
  padding: 1rem 3.5rem;
  min-width: 7.75rem;
  font-size: 1.2rem;
  color: ${color.white};
  background: ${color.P100};
  border-radius: 0.5rem;
  border: none;
  font-weight: bold;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  cursor: pointer;
  box-sizing: border-box;
  ${(p) => (!p.isDisabled ? SignUpButtonHover : SignUpButtonDisabled)};
`;

const SignUpButtonMobile = styled.input<{ isDisabled: boolean }>`
  width: calc(100% - 1.5rem);
  position: absolute;
  left: 0.75rem;
  bottom: 0.75rem;
  padding: 1rem 0;
  font-size: 1.2rem;
  color: ${color.white};
  background: ${color.P100};
  border-radius: 0.5rem;
  border: none;
  font-weight: bold;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  cursor: pointer;
  ${(p) => (!p.isDisabled ? SignUpButtonHover : SignUpButtonDisabled)};

  @media only screen and (min-width: 40rem) {
    display: none;
  }
`;

const ErrorText = styled(P2)`
  font-weight: 400;
  color: #ff5f5f;
  text-align: right;
  min-height: 2.25rem;
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
`;

const Disclaimer = styled.div`
  margin-top: 2.5rem;

  @media only screen and (max-width: 52rem) {
    margin-top: 1rem;
  }

  p {
    font-family: ${fonts.body};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.075rem;
    color: ${color.B2100};
  }
`;

const BCorpLogo = styled.img`
  margin-left: 4rem;

  @media only screen and (max-width: 55rem) {
    margin: 0;
  }
`;
