import { config } from "@utils";

import { NavIconNames } from "../../../../atoms/NavIcon";
import { MenuName, NavLinks } from "./Navbar.types";

const jpNavLinks: NavLinks = [
  // TODO - add JP login links
  // {
  //   title: MenuName.login,
  //   id: "login-nav-title",
  //   primaryLinks: [
  //     {
  //       link: `${config.TEAM_URL}/login?region=JP`,
  //       text: "Employers",
  //       external: true,
  //       iconColor: "S3100",
  //       iconType: NavIconNames.hrLogin,
  //     },
  //     {
  //       link: `${config.MEMBERS_URL}/login?region=JP`,
  //       text: "Employees",
  //       external: true,
  //       iconColor: "S2100",
  //       iconType: NavIconNames.employeeLogin,
  //     },
  //   ],
  // },
  {
    title: MenuName.region,
    titleTranslation: "地域",
    id: "login-nav-title",
    primaryLinks: [
      {
        locale: "uk",
        localeLink: true,
        link: "GB-UKM",
        text: "UK",
      },
      {
        locale: "us",
        localeLink: true,
        text: "US",
        link: "US",
      },
      {
        locale: "za",
        localeLink: true,
        text: "SA",
        link: "ZA",
      },
    ],
  },
];

export default jpNavLinks;
