import { YuLifeLocale } from "hooks";

export enum MenuName {
  blank = "",
  products = "Products",
  features = "Features",
  featuresIncluded = "Features included",
  resources = "Resources",
  about = "About",
  login = "Log In",
  legal = "Legal",
  region = "Region",
  solutions = "Our Solutions"
}

export enum NavbarType {
  primary = "Primary",
  primaryInverted = "Primary Inverted",
}

export interface Link {
  link: string;
  localeLink?: boolean;
  locale?: YuLifeLocale;
  text: string;
  external?: boolean;
  iconType?: string;
  iconColor?: string;
  isLive?: boolean;
}

export interface SecondaryLinks {
  link: string;
  text: string;
  isLive?: boolean;
}

export interface LinkItem {
  title: MenuName;
  titleTranslation?: string;
  id: string;
  primaryLinks: Link[];
  secondaryLinks?: Link[];
  secondaryTitle?: MenuName;
}

export interface NavLinks extends Array<LinkItem> {}
