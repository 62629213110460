import { config } from "@utils";
import { NavIconNames } from "../../../../atoms/NavIcon";
import { MenuName, NavLinks } from "./Navbar.types";
import { DynamicRoutes, StaticRoutes } from "@routes";

const ukNavLinks: NavLinks = [
  {
    title: MenuName.products,
    id: "products-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.insuranceGroupHealthInsurance,
        text: "Health Insurance",
      },
      {
        link: StaticRoutes.insuranceGroupLifeInsurance,
        text: "Life Insurance",
      },
      {
        link: StaticRoutes.insuranceGroupIncomeProtection,
        text: "Income Protection",
      },
      {
        link: StaticRoutes.insuranceGroupDentalInsurance,
        text: "Dental Insurance",
      },
      {
        link: StaticRoutes.insuranceGroupInsurance,
        text: "Group Protection",
      },
      {
        link: StaticRoutes.insuranceAppOnly,
        text: "App only",
      },
      {
        link: StaticRoutes.whatsIncluded,
        text: "What's included",
      },
    ],
    secondaryLinks: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "Employee App",
      },
      {
        link: StaticRoutes.featuresEmployeeEngagement,
        text: "Employee Engagement",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "Incentives & Rewards",
      },
      {
        link: StaticRoutes.featuresEmployeeAssistanceProgramme,
        text: "Mental Health (EAP)",
      },
      {
        link: StaticRoutes.featuresVirtualGpServices,
        text: "Virtual GP",
      },
      {
        link: StaticRoutes.featuresSocialImpactBenefits,
        text: "Social Impact (ESG)",
      },
      {
        link: StaticRoutes.featuresPortal,
        text: "Employee Portal",
      },
      {
        link: StaticRoutes.featuresYustore,
        text: "YuStore",
      },
      {
        link: StaticRoutes.featuresHrInsights,
        text: "Reporting",
      },
    ],
    secondaryTitle: MenuName.features,
  },
  {
    title: MenuName.resources,
    id: "resources-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.resourceHub,
        text: "Resource Hub",
      },
      {
        link: StaticRoutes.blog,
        text: "Blogs",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "Case Studies",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "News & Events",
      },
      {
        link: StaticRoutes.adviser,
        text: "Adviser",
      },
    ],
  },
  {
    title: MenuName.about,
    id: "about-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.aboutUs,
        text: "About Us",
      },
      {
        link: StaticRoutes.careers,
        text: "Careers",
      },
      {
        link: StaticRoutes.press,
        text: "Press",
      },
      {
        link: StaticRoutes.contactUs,
        text: "Contact",
      },
    ],
  },
  {
    title: MenuName.login,
    id: "login-nav-title",
    primaryLinks: [
      {
        link: `${config.TEAM_URL}/login`,
        text: "Employers",
        external: true,
        iconColor: "S3100",
        iconType: NavIconNames.hrLogin,
      },
      {
        link: `${config.MEMBERS_URL}/login`,
        text: "Employees",
        external: true,
        iconColor: "S2100",
        iconType: NavIconNames.employeeLogin,
      },
    ],
  },
  {
    title: MenuName.region,
    id: "login-nav-title",
    primaryLinks: [
      {
        locale: "uk",
        localeLink: true,
        link: "GB-UKM",
        text: "UK",
      },
      {
        locale: "us",
        localeLink: true,
        text: "US",
        link: "US",
      },
      {
        locale: "za",
        localeLink: true,
        text: "SA",
        link: "ZA",
      },
    ],
  },
];

export default ukNavLinks;
