import { config } from "@utils";

import { NavIconNames } from "../../../../atoms/NavIcon";
import { MenuName, NavLinks } from "./Navbar.types";
import { StaticRoutes, ExternalRoutes } from "@routes";

const usProductLinks = [
  {
    link: StaticRoutes.ourSolutionsIndividuals,
    text: "For Individuals",
  },
  {
    link: StaticRoutes.ourSolutionsBusinesses,
    text: "For Businesses",
  },
  {
    link: StaticRoutes.ourSolutionsInsuranceCarriers,
    text: "For Insurance carriers",
  },
  {
    link: StaticRoutes.ourSolutionsBrokersAndAdvisers,
    text: "For Brokers and Advisers",
  },
  {
    link: StaticRoutes.insuranceAppOnly,
    text: "App only",
  },
];

const usNavLinks: NavLinks = [
  {
    title: MenuName.solutions,
    id: "products-nav-title",
    primaryLinks: usProductLinks,
    secondaryLinks: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "Employee App",
      },
      {
        link: StaticRoutes.featuresEmployeeEngagement,
        text: "Employee Engagement",
      },
      {
        link: StaticRoutes.featuresPortal,
        text: "Employer Portal",
      },
      {
        link: StaticRoutes.featuresWellbeingHub,
        text: "Wellbeing Hub",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "Incentives & Rewards",
      },
      {
        link: StaticRoutes.featuresSocialImpactBenefits,
        text: "Social Impact (ESG)",
      },
    ],
    secondaryTitle: MenuName.featuresIncluded,
  },
  {
    title: MenuName.resources,
    id: "resources-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.resourceHub,
        text: "Resource Hub",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "Case Studies",
      },
      {
        link: StaticRoutes.blog,
        text: "Blogs",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "News & Events",
      },
      {
        link: ExternalRoutes.faqChat,
        text: "FAQ",
        external: true,
      },
    ],
  },
  {
    title: MenuName.about,
    id: "about-nav-title",
    primaryLinks: [
      {
        iconColor: "S3100",
        iconType: NavIconNames.ourStory,
        link: StaticRoutes.aboutUs,
        text: "About Us",
      },
      {
        iconColor: "S2100",
        iconType: NavIconNames.ourTeam,
        link: StaticRoutes.team,
        text: "Our team",
      },
      {
        iconColor: "S4100",
        iconType: NavIconNames.press,
        link: StaticRoutes.press,
        text: "Press",
      },
      {
        iconColor: "S3100",
        iconType: NavIconNames.contactUs,
        link: StaticRoutes.careers,
        text: "Careers",
      },
    ],
  },
  {
    title: MenuName.login,
    id: "login-nav-title",
    primaryLinks: [
      {
        link: `${config.TEAM_URL}/login?region=US`,
        text: "Admin",
        external: true,
        iconColor: "S3100",
        iconType: NavIconNames.hrLogin,
      },
      {
        link: `${config.MEMBERS_URL}/login?region=US`,
        text: "Member",
        external: true,
        iconColor: "S2100",
        iconType: NavIconNames.employeeLogin,
      },
    ],
  },
  {
    title: MenuName.region,
    id: "login-nav-title",
    primaryLinks: [
      {
        locale: "uk",
        localeLink: true,
        link: "GB-UKM",
        text: "UK",
      },
      {
        locale: "us",
        localeLink: true,
        text: "US",
        link: "US",
      },
      {
        locale: "za",
        localeLink: true,
        text: "SA",
        link: "ZA",
      },
    ],
  },
];

export default usNavLinks;
