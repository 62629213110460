import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { MenuArrow } from "../atoms/MenuArrow";
import { color as YU_COLOURS } from "@styles";
import { NavbarType } from "components/organisms/Navigation/components/Navbar/Navbar.types";

export type MenuItemProps = PropsWithChildren<{
  isActive?: boolean;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  navbarType: NavbarType;
  isScrolled: boolean;
  hasArrow?: boolean;
}>;

function getMenuItemColor(navbarType: NavbarType, isScrolled: boolean, isActive: boolean) {
  if (isActive && navbarType === NavbarType.primaryInverted && !isScrolled) {
    return YU_COLOURS.S330;
  }

  if (isActive) {
    return YU_COLOURS.P100;
  }

  if (navbarType === NavbarType.primaryInverted && !isScrolled) {
    return YU_COLOURS.white;
  }

  return YU_COLOURS.B1100;
}

export const MenuItem: FC<MenuItemProps> = ({
  children,
  onMouseLeave,
  onMouseEnter,
  isActive = false,
  navbarType,
  isScrolled,
  hasArrow = true,
}) => (
  <MenuItemWrap
    isScrolled={isScrolled}
    navbarType={navbarType}
    color={getMenuItemColor(navbarType, isScrolled, isActive)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
    {hasArrow && (
      <MenuArrow
        navbarType={navbarType}
        isScrolled={isScrolled}
        colour={getMenuItemColor(navbarType, isScrolled, isActive)}
      />
    )}
  </MenuItemWrap>
);

export const MenuItemWrap = styled.div<{
  color: string;
  navbarType: NavbarType;
  isScrolled: boolean;
}>`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  letter-spacing: 0.075rem;
  text-align: right;
  color: ${(p) => p.color || YU_COLOURS.B1100};
  cursor: pointer;
  text-wrap: nowrap;
`;
