import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { shrinkNav } from "components/templates/Breakpoints";
import { useLocale } from "hooks";
import { Link } from "@components/atoms";
import { FC } from "react";
import styled from "styled-components";
import { Container, ResizableContainer } from "./styles";

export const MegaMenuLogIn: FC = () => {
  const { locale } = useLocale();
  const upperCaseLocale = locale === "za" ? "SA" : locale === "jp" ? "JP" : locale.toUpperCase();

  return (
    <ResizableContainer>
      <StyledContainer>
        <Column>
          <Link href={`https://team.yulife.com/login?region=${upperCaseLocale}`}>
            <MegaMenuSmallBlock
              title={locale === "us" ? "Admin portal" : "Employers"}
              desc={`Log in to the ${locale === "us" ? "Admin" : "HR"} Portal to manage your company on YuLife`}
              width="24rem"
            />
          </Link>
          <Link href={`https://members.yulife.com/login?region=${upperCaseLocale}`}>
            <MegaMenuSmallBlock
              title={locale === "us" ? "Member portal" : "Employees"}
              desc="Log in to the Member Portal to access your YuLife products"
              width="24rem"
            />
          </Link>
        </Column>
      </StyledContainer>
    </ResizableContainer>
  );
};

const StyledContainer = styled(Container)`
  width: 26rem;
  flex-direction: column;
  position: absolute;
  right: 2.5rem;

  ${shrinkNav} {
    right: -6rem;
  }
`;

const Column = styled.div`
  padding: 1rem;
`;
